.freemium-banner{
  background-color: #F0FCFF;
  padding: 75px 39px 46px 71px;
  border-radius: 42px;
  height: max-content;
  position: relative;

  @media (max-width: 1100px) {
      padding: 40px;
  }
  &__h3{
    font-size: 42px;
    line-height: 59px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #2330BA;
    margin-bottom: 40px;
    max-width: 883px;

    @media (max-width: 1100px) {
        max-width: 700px;
    }
  }
  &__h4{

    font-size: 28px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 3rem;

    &-blue{
      font-size: 28px;
      font-weight: 800;
      line-height: 39px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #2330BA;
    }
    span{
      color: #2330BA;
      text-align: left;
      font-size: 28px;
      line-height: 39px;
      letter-spacing: 0.03em;
      font-weight: 800;
    }
    a{
      font-size: 28px;
      font-weight: 600;
      line-height: 39px;
      letter-spacing: 0.03em;
      text-align: left;
      text-decoration: underline;
      color: #122443;

      .freemium-banner__span{
        text-decoration: none;
        color: #122443;
        font-weight: 600;
      }
    }
  }
  &__row{
    display: flex;
    flex-wrap: wrap;
    width: 927px;
    margin-bottom: 80px;
    justify-content: space-between;
  }
  &__rowBottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__point{
    width: 260px;
    margin-right: 49px;
    margin-bottom: 24px;
  }
  &__point:nth-child(3){
    width: 296px;
    margin-right: 0;
  }
  &__circle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: white;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.08em;
    text-align: left;
    color: #2330BA;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-align: left;

  }
  &__h5{
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-align: right;
    margin-top: 31px;
    margin-right: 152px;
  }
  &__btn{
    width: 260px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 12px;
    background-color: #FECC62;
    margin-top: 22px;
    color: #122443;
    transition-duration: 300ms;
  }
  &__btn:hover{
    opacity: 0.7;
  }
  &__close{
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: white;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  transition-duration: 300ms;
    img{
      height: 34px;
      width: 34px;
    }
  }
  &__close:hover{
    opacity: 0.7;
  }
  
  @media (max-width: 1000px) {
    padding-top: 57px;

    &__h5{
      margin-right: 0;
    }
    &__row{
      margin-bottom: 0px;
      width: 100% !important;
    }
    &__close{
      top: 26px;
      right: 26px;
      width: 39px;
      height: 39px;
      img{
        width: 16px;
        height: 16px;
      }
    }
    h3{
      font-size: 26px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0.03em;
      text-align: left;
      margin-bottom: 22px;
    }
    &__h4{
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.03em;
      text-align: left;
      max-width: 600px;
      margin-bottom: 20px;
    }
    &__h4-blue{
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
      letter-spacing: 0.03em;
      text-align: left;
    }
    &__point{
      display: flex;
      flex-direction: row;
    }
    .freemium-banner__row{
      width: 600px;
    }
    .freemium-banner__point{
      width: 312px;

      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-align: left;
        width: 243px;
      }
      div{
        width: 50px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.08em;
        text-align: left;
        margin-right: 19px;
      }
    }
  }

  @media (max-width: 640px) {
    padding-left: 40px;
    padding-right: 20px;
    width: 375px;
    h3{
      max-width: 283px;
    }
    &__h4{
      max-width: 273px;
    }
    &__rowBottom{
      flex-direction: column-reverse;
    }
  }
}