.tests-page {
    position: relative;
    padding-bottom: 0;
    background-image: $gradient-red;

    &__main {
        margin: -5rem -24px 0;
        padding: 5rem 1.5rem 2.5rem;
    }

    &__main-title {
        color: #fff;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.4px;
        margin-bottom: 0.5rem;
    }

    &__title {
        color: #ffffff;
    }

    &__passed {
        flex-grow: 1;
        padding: 1.5rem;
        background: #f4f7f9;
        margin: -1rem -1.5rem 0;
        border-radius: 20px 20px 0px 0px;

        animation-name: selectionUp;
        animation-duration: 0.6s;
        animation-timing-function: ease-out;
    }

    &__passed-title {
        color: #122443;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.4px;
        margin-bottom: 12px;
    }

    &__date {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    &__passed-test {
        & + & {
            margin-top: 1rem;
        }
    }
}
